

.MuiMenuItem-root:hover {
    color: black !important;
    background: #d7d6d6e2 !important;
  }

.logo{
    position: absolute;
    left: 10px;
    padding: 10px;
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.introjs-tooltip {
  width: 80vw;
  max-width:400px !important;
  min-width: 300px !important;
}

.MuiListSubheader-root{
    font-weight: bold !important;
    color: rgb(99, 115, 129) !important;
}

.MuiMenuItem-root{
    padding: 8px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 10px !important;
    margin: 3px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .MuiListSubheader-root{
   background-color: inherit !important;
   margin-left: 10px;
   margin-top:20px !important;
   margin-bottom:10px !important;
   line-height:10px !important;
   color: rgb(154, 153, 153) !important;
   position:relative !important;
  }

.subHeader{
    margin-top:20px !important;
    text-align:3px !important;
    font-weight: normal !important;
}

  .MuiListItemIcon-root{
    color: inherit !important;
    font-size:18px !important;
  }


.sidebarburger{
    position: relative;
    right:12px;
    margin-bottom:0px;
    font-size:30px;
    text-align: right !important;
    padding-right: 10px;
}

@media only screen and (max-width: 577px) {
    /* width */
    ::-webkit-scrollbar {
        display: none;
    }
    .sidebar{
        box-shadow: none;
        visibility:hidden;
    }
    .sidebarburger{
        display:none;
        }
}