
.centers{
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-height: 100vh;
    padding: 20px 0px;
}
.newUserHeader{
    font-size: large;
    align-self: center;
}
